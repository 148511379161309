var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "TASK_CONSTRAINT" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("task.constraint.title"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.alertError,
                callback: function ($$v) {
                  _vm.alertError = $$v
                },
                expression: "alertError",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.$t("error.attention_required")) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "task-constraint",
                label: _vm.$t("task.field.constraint"),
                "label-for": "task-constraint_type",
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-2 mb-xl-0",
                      attrs: { cols: "12", xl: "6" },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "task-constraint_type",
                          options: _vm.optionConstraint,
                          "value-field": "value",
                          "text-field": "text",
                        },
                        on: { input: _vm.constraintTypeChanged },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pl-xl-0", attrs: { cols: "12", xl: "6" } },
                    [
                      _c("b-form-datepicker", {
                        attrs: {
                          id: "task-constraintDate",
                          disabled: _vm.disableConstraintDate,
                          "today-button": "",
                          "reset-button": "",
                          "close-button": "",
                          "hide-header": "",
                          "label-today-button": _vm.$t("date.today"),
                          "label-reset-button": _vm.$t("date.reset"),
                          "label-close-button": _vm.$t("date.close"),
                          "today-button-variant": "primary",
                          "reset-button-variant": "danger",
                          "close-button-variant": "secondary",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "button-content",
                            fn: function (ref) {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "calendar-days"] },
                                }),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showDateError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.errors.first("date")) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }